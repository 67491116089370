<template>
  <div style="background: #eccece;">
    <div class="Shop_SubmitApprovalList">
      <!-- PC显示 -->
      <el-card v-if="isPCH5Text === 'PC'">
        <el-row class="Tasktitle" v-loading="loadingTask">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <el-descriptions :column="3" size="medium" border style="width: 100%;">
              <el-descriptions-item label="项目名称">
                <el-tag v-if="TaskData.ProjectName">
                  {{ TaskData.ProjectName }}
                </el-tag>
                <span v-else>&nbsp;</span>
              </el-descriptions-item>
              <el-descriptions-item label="任务名称">
                <el-tag v-if="TaskData.TaskName">
                  {{ TaskData.TaskName }}
                </el-tag>
                <span v-else>&nbsp;</span>
              </el-descriptions-item>

              <el-descriptions-item label="任务创建时间">
                <el-tag v-if="TaskData.CreateTime">
                  {{
                    TaskData.CreateTime ? TaskData.CreateTime.substring(0, 19) : ""
                  }}
                </el-tag>
                <span v-else>&nbsp;</span>
              </el-descriptions-item>
            </el-descriptions>
            <div style="margin-left: 20px;margin-right: 20px;cursor: pointer;position: relative;"
              @click="drawerVisible = true">
              <el-tooltip class="item" effect="dark" content="通知消息" placement="left">
                <el-badge :value="msgCount" class="item" :max="99" :hidden="Boolean(msgCount === 0)">
                  <div style="width: 50px;height: 50px;color: #000;border-radius: 50%;position: relative;">
                    <i class="el-icon-bell"
                      style="font-size: 40px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"></i>
                  </div>
                </el-badge>

              </el-tooltip>

            </div>
          </div>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" v-loading="loadingLeft">
            <div class="div">
              设备/材料报审
              <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px"
                @click="addAuditOpen(1)"></el-button>
            </div>
            <el-card class="deepCard">
              <div>
                <div v-for="item in auditMaterialsInfoList" :key="item.id" style="
                  border: 1px solid #d3cbcb;
                  margin-top: 10px;
                  border-radius: 10px;
                  cursor: pointer;
                  padding: 10px 0px;
                " class="auditMaterialsInfoList" @click="editAuditOpen(item, 1)">
                  <el-row style="display: flex; align-items: center; font-size: 14px">
                    <div style="width: 84px; text-align: right" :span="4">
                      <el-tag style="
                        font-weight: bold;
                        letter-spacing: 1px;
                        margin-left: 10px;
                        text-align: center;
                      " effect="plain">{{
                        item.ApprovalType == 1
                          ? "设备材料"
                          : item.ApprovalType == 2
                            ? "深化图纸"
                            : ""
                      }}
                      </el-tag>
                    </div>
                    <div style="width: calc(100% - 324px); margin-left: 10px">
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单名称：</span>
                          <span :title="item.ApprovalName">{{
                            item.ApprovalName
                          }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单编号：</span>
                          <span :title="item.ApprovalCode">{{
                            item.ApprovalCode
                          }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单批次：</span> 第
                          <span style="font-weight: bold; color: #d1100b">{{
                            item.ApprovalBatch
                          }}</span>
                          批次
                        </el-col>
                      </el-row>
                    </div>
                    <div style="width: 60px">
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="primary"
                        v-if="item.Status == 0">未提审
                      </el-tag>
                      <el-tag type="success" style="height: 25px; line-height: 25px; padding: 0 10px"
                        v-if="item.Status == 1">{{ item.ApprovalBatch == 1 ? "已提审" : "再提审" }}
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="info"
                        v-if="item.Status == 2">审核中
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="danger"
                        v-if="item.Status == 3">已驳回
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="success"
                        v-if="item.Status == 4">已通过
                      </el-tag>
                    </div>
                    <div style="width: 160px; margin-left: 10px; text-align: center">
                      <el-row>
                        <el-button size="mini" type="primary" v-if="item.Status == 0"
                          @click.stop="AuditOpen(item, 1)">提审GO
                        </el-button>
                        <el-button size="mini" type="primary" v-if="item.Status > 0"
                          @click.stop="AuditOpen(item, 3)">转发链接</el-button>
                        <el-button size="mini" type="primary" icon="el-icon-delete" v-if="item.Status == 0"
                          @click.stop="AuditDelete(item, 1)"></el-button>
                        <el-button size="mini" type="primary" v-if="item.Status == 3"
                          @click.stop="addAuditOpen(1, item, null, '追加')">追加</el-button>
                      </el-row>

                      <el-row style="margin-top: 10px">
                        <span style="
                          text-align: center;
                          color: #c0c4cc;
                          font-size: 13px;
                          margin-top: 5px;
                        ">
                          {{
                            item.CreateTime
                              ? item.CreateTime.substring(0, 19)
                              : ""
                          }}
                        </span>
                      </el-row>
                    </div>
                  </el-row>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12" v-loading="loadingRight">
            <div class="div">
              深化图纸报审
              <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px"
                @click="addAuditOpen(2)"></el-button>
            </div>
            <el-card class="deepCard">
              <div>
                <div v-for="item in auditImgInfoList" :key="item.id" style="
                  border: 1px solid #d3cbcb;
                  margin-top: 10px;
                  border-radius: 10px;
                  cursor: pointer;
                  padding: 10px 0px;
                " class="auditMaterialsInfoList" @click="editAuditOpen(item, 2)">
                  <el-row style="display: flex; align-items: center; font-size: 14px">
                    <div style="width: 84px; text-align: right">
                      <el-tag style="
                        font-weight: bold;
                        letter-spacing: 1px;
                        text-align: center;
                      " effect="plain">{{
                        item.ApprovalType == 1
                          ? "设备材料"
                          : item.ApprovalType == 2
                            ? "深化图纸"
                            : ""
                      }}
                      </el-tag>
                    </div>
                    <div style="width: calc(100% - 324px); margin-left: 10px">
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单名称：</span>
                          <span :title="item.ApprovalName">{{
                            item.ApprovalName
                          }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单编号：</span>
                          <span :title="item.ApprovalCode">{{
                            item.ApprovalCode
                          }}</span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col style="line-height: 1.5" class="ellipsis1">
                          <span style="font-weight: bold">报审单批次：</span> 第
                          <span style="font-weight: bold; color: #d1100b">{{
                            item.ApprovalBatch
                          }}</span>
                          批次
                        </el-col>
                      </el-row>
                    </div>
                    <div style="width: 60px">
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="primary"
                        v-if="item.Status == 0">未提审
                      </el-tag>
                      <el-tag type="success" style="height: 25px; line-height: 25px; padding: 0 10px"
                        v-if="item.Status == 1">{{ item.ApprovalBatch == 1 ? "已提审" : "再提审" }}
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="info"
                        v-if="item.Status == 2">审核中
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="danger"
                        v-if="item.Status == 3">已驳回
                      </el-tag>
                      <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="success"
                        v-if="item.Status == 4">已通过
                      </el-tag>
                    </div>
                    <div style="width: 160px; margin-left: 10px; text-align: center">
                      <el-row>
                        <el-button size="mini" type="primary" @click.stop="AuditOpen(item, 2)"
                          v-if="item.Status == 0">提审GO
                        </el-button>
                        <el-button size="mini" type="primary" v-if="item.Status > 0"
                          @click.stop="AuditOpen(item, 3)">转发链接
                        </el-button>
                        <el-button size="mini" type="primary" icon="el-icon-delete" :disabled="item.Status != 0"
                          @click.stop="AuditDelete(item, 2)" v-if="item.Status == 0"></el-button>

                        <el-button size="mini" type="primary" v-if="item.Status == 3"
                          @click.stop="addAuditOpen(2, item, null, '追加')">追加</el-button>
                      </el-row>
                      <el-row style="margin-top: 10px">
                        <span style="
                          text-align: center;
                          color: #c0c4cc;
                          font-size: 13px;
                          margin-top: 5px;
                        ">
                          {{
                            item.CreateTime
                              ? item.CreateTime.substring(0, 19)
                              : ""
                          }}
                        </span>
                      </el-row>
                    </div>
                  </el-row>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
      <!-- H5显示 -->
      <div class="Shop_SubmitApprovalListH5" v-else>
        <el-tabs v-model="activeName" type="border-card" stretch>
          <el-tab-pane label="设备/材料报审" name="1" style="position: relative">
            <el-button icon="el-icon-plus" type="primary" size="mini" circle style="
              font-size: 30px;
              position: fixed;
              bottom: 45px;
              right: 13px;
              z-index: 99;
            " @click="addAuditOpen(1)"></el-button>
            <div v-loading="loadingLeft">
              <el-row class="TasktitleH5" v-loading="loadingTask">
                <el-descriptions :column="1" size="medium" border>
                  <el-descriptions-item label="项目名称">
                    <span v-if="TaskData.ProjectName">
                      {{ TaskData.ProjectName }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="任务名称">
                    <span v-if="TaskData.TaskName">
                      {{ TaskData.TaskName }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>

                  <el-descriptions-item label="任务创建时间">
                    <span v-if="TaskData.CreateTime">
                      {{
                        TaskData.CreateTime
                          ? TaskData.CreateTime.substring(0, 19)
                          : ""
                      }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>
                </el-descriptions>
              </el-row>

              <div v-for="item in auditMaterialsInfoList" :key="item.id"
                style="border: 1px solid #ccc; padding: 10px; margin-top: 5px;margin-bottom: 5px;"
                class="auditMaterialsInfoList" @click="editAuditOpen(item, 1)">
                <div style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  z-index: 1;
                ">
                  <div style="width: calc(100% - 20px)">
                    <div style="line-height: 30px; font-size: 14px">
                      <div class="ellipsis">
                        报审单编号：{{ item.ApprovalCode }}
                      </div>
                      <div class="ellipsis">
                        报审单名称：{{ item.ApprovalName }}
                      </div>
                      <div class="ellipsis">
                        报审单批次： 第
                        <span style="font-weight: bold; color: #d1100b">{{
                          item.ApprovalBatch
                        }}</span>
                        批次
                      </div>
                    </div>
                    <div style="
                      display: flex;
                      justify-content: space-between;
                      line-height: 30px;
                      align-items: center;
                    ">
                      <el-tag effect="plain" size="mini">{{
                        item.ApprovalType == 1
                          ? "设备材料"
                          : item.ApprovalType == 2
                            ? "深化图纸"
                            : ""
                      }}</el-tag>

                      <div style="font-size: 12px">
                        {{
                          item.CreateTime ? item.CreateTime.substring(0, 19) : ""
                        }}
                      </div>
                    </div>
                    <div style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    ">
                      <div>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="primary"
                          v-if="item.Status == 0">未提审
                        </el-tag>
                        <el-tag type="success" style="height: 25px; line-height: 25px; padding: 0 10px"
                          v-if="item.Status == 1">{{ item.ApprovalBatch == 1 ? "已提审" : "再提审" }}
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="info"
                          v-if="item.Status == 2">审核中
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="danger"
                          v-if="item.Status == 3">已驳回
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="success"
                          v-if="item.Status == 4">已通过
                        </el-tag>
                      </div>
                      <el-row style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      ">
                        <el-button size="mini" type="primary" v-if="item.Status == 0"
                          @click.stop="AuditOpen(item, 1)">提审GO
                        </el-button>
                        <el-button size="mini" type="primary" v-if="item.Status > 0"
                          @click.stop="AuditOpen(item, 3)">转发链接</el-button>
                        <el-button size="mini" type="primary" icon="el-icon-delete" v-if="item.Status == 0"
                          @click.stop="AuditDelete(item, 1)"></el-button>
                        <el-button size="mini" type="primary" v-if="item.Status == 3"
                          @click.stop="addAuditOpen(1, item, null, '追加')">追加</el-button>
                      </el-row>
                    </div>
                  </div>
                  <div style="width: 20px">
                    <i class="el-icon-arrow-right" style="font-size: 30px; width: 20px"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="深化图纸报审" name="2">
            <el-button icon="el-icon-plus" type="primary" size="mini" circle style="
              font-size: 30px;
              position: fixed;
              bottom: 45px;
              right: 13px;
              z-index: 99;
            " @click="addAuditOpen(2)"></el-button>
            <div v-loading="loadingRight">
              <el-row class="TasktitleH5" v-loading="loadingTask">
                <el-descriptions :column="1" size="medium" border>
                  <el-descriptions-item label="项目名称">
                    <span v-if="TaskData.ProjectName">
                      {{ TaskData.ProjectName }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="任务名称">
                    <span v-if="TaskData.TaskName">
                      {{ TaskData.TaskName }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>

                  <el-descriptions-item label="任务创建时间">
                    <span v-if="TaskData.CreateTime">
                      {{
                        TaskData.CreateTime
                          ? TaskData.CreateTime.substring(0, 19)
                          : ""
                      }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </el-descriptions-item>
                </el-descriptions>
              </el-row>
              <div v-for="item in auditImgInfoList" :key="item.Id"
                style="border: 1px solid #ccc; padding: 10px; margin-top: 5px" class="auditMaterialsInfoList"
                @click="editAuditOpen(item, 1)">
                <div style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  z-index: 1;
                ">
                  <div style="width: calc(100% - 20px)">
                    <div style="line-height: 30px; font-size: 14px">
                      <div class="ellipsis">
                        报审单编号：{{ item.ApprovalCode }}
                      </div>
                      <div class="ellipsis">
                        报审单名称：{{ item.ApprovalName }}
                      </div>
                      <div class="ellipsis">
                        报审单批次： 第
                        <span style="font-weight: bold; color: #d1100b">{{
                          item.ApprovalBatch
                        }}</span>
                        批次
                      </div>
                    </div>
                    <div style="
                      display: flex;
                      justify-content: space-between;
                      line-height: 30px;
                      align-items: center;
                    ">
                      <el-tag effect="plain" size="mini">{{
                        item.ApprovalType == 1
                          ? "设备材料"
                          : item.ApprovalType == 2
                            ? "深化图纸"
                            : ""
                      }}</el-tag>

                      <div style="font-size: 12px">
                        {{
                          item.CreateTime ? item.CreateTime.substring(0, 19) : ""
                        }}
                      </div>
                    </div>
                    <div style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    ">
                      <div>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="primary"
                          v-if="item.Status == 0">未提审
                        </el-tag>
                        <el-tag type="success" style="height: 25px; line-height: 25px; padding: 0 10px"
                          v-if="item.Status == 1">{{ item.ApprovalBatch == 1 ? "已提审" : "再提审" }}
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="info"
                          v-if="item.Status == 2">审核中
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="danger"
                          v-if="item.Status == 3">已驳回
                        </el-tag>
                        <el-tag style="height: 25px; line-height: 25px; padding: 0 10px" type="success"
                          v-if="item.Status == 4">已通过
                        </el-tag>
                      </div>
                      <el-row style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      ">
                        <el-button size="mini" type="primary" @click.stop="AuditOpen(item, 2)"
                          v-if="item.Status == 0">提审GO
                        </el-button>
                        <el-button size="mini" type="primary" v-if="item.Status > 0"
                          @click.stop="AuditOpen(item, 3)">转发链接
                        </el-button>
                        <el-button size="mini" type="primary" icon="el-icon-delete" :disabled="item.Status != 0"
                          @click.stop="AuditDelete(item, 2)" v-if="item.Status == 0"></el-button>
                        <el-button size="mini" type="primary" v-if="item.Status == 3"
                          @click.stop="addAuditOpen(2, item, null, '追加')">追加</el-button>
                      </el-row>
                    </div>
                  </div>
                  <div style="width: 20px">
                    <i class="el-icon-arrow-right" style="font-size: 30px; width: 20px"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="消息" name="消息" v-loading="loadingMsg">
            <span slot="label">
              <span style="display: flex;align-items: center;justify-content: center">
                <span>消息</span>
                <span
                  style="display: inline-block;background-color: #f56c6c;color: #fff;height: 16px;line-height: 16px;border-radius: 10px;padding: 0 5px;font-size: 10px;box-sizing: border-box;"
                  v-if="msgCount > 0">{{
                    msgCount > 99 ? '99+' : msgCount
                  }}</span>
              </span>
            </span>
            <div
              style="display: flex;align-items: center;justify-content: space-between;line-height: 24px;padding: 10px 5px;border-bottom:1px solid #ccc;margin-bottom: 10px;">
              <span style="font-size: 14px;">当前手机号：{{ RGApprovalUnitUserInfo.Phone }}</span>
              <el-button :style="`padding: 5px;color:${msgCount != 0 ? '#3370ff' : ''}`" type="text" @click="UPSendMsg"
                :disabled="msgCount == 0">全部设为已读</el-button>
            </div>
            <div style="height:calc(100vh - 161px);overflow-y: auto;padding: 0 5px;">
              <div v-for="item in SendMsgList" :key="item.Id"
                style="position: relative;margin-top: 5px;margin-bottom: 5px;" @click="clickSendMsg(item)">
                <el-card>
                  <span style="font-size: 14px;line-height: 1.6;"> {{ item.MsgInfo
                    }}</span>
                  <el-tag size="small" type="success" style="position: absolute;right: 0px;top: 0px;"
                    v-if="item.IsBeenRead">已读</el-tag>
                  <el-tag size="small" style="position: absolute;right: 0px;top: 0px;"
                    v-if="!item.IsBeenRead">未读</el-tag>
                </el-card>
              </div>
            </div>
            <div
              style="height: 40px;width: 100%;text-align: center;display: flex;align-items: center;justify-content: center">
              <el-pagination layout="prev, pager, next,total" :total="pagination.total" background small
                :page-size="pagination.pageSize" :page-count="pagination.pageCount" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div v-if="isPCH5Text === 'PC'">
        <el-dialog :visible.sync="addAuditFlag" width="60%" append-to-body :show-close="false" :close="addAuditClose"
          custom-class="addAudit">
          <div slot="title">
            <div style="
              line-height: 33px;
              display: flex;
              justify-content: space-between;
            ">
              <span style="font-size: 20px; font-weight: bold; color: #606266">{{
                addAuditType == 1
                  ? `${AuditTitle}设备/材料报审`
                  : `${AuditTitle}深化图纸报审`
              }}</span>

              <img style="
                width: 33px;
                height: 33px;
                margin-right: 10px;
                cursor: pointer;
              " src="../../../assets/delete.png" alt="" @click="addAuditClose" />
            </div>
          </div>
          <el-form :model="addAuditFlagForm" :rules="addAuditFlagRules" ref="addAuditFlagForm" label-width="200px"
            style="width: 90%; font-weight: bold" class="addAuditForm" v-loading.fullscreen.lock="addAuditLoadings">
            <el-form-item label="项目名称：">
              <el-input v-model="TaskData.ProjectName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="任务名称：">
              <el-input v-model="TaskData.TaskName" autocomplete="off" disabled></el-input>
            </el-form-item>

            <el-form-item label="专业：" prop="ApprovalMajor">
              <el-select v-model="addAuditFlagForm.ApprovalMajor" clearable placeholder="请选择" filterable
                @change="changeApprovalMajor" default-first-option>
                <el-option v-for="v in ApprovalMajorList" :key="v.DicName" :label="v.DicName"
                  :value="v.DicName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'" prop="ApprovalName">
              <el-input v-model="addAuditFlagForm.ApprovalName" autocomplete="off" @input="changeApprovalName"
                :disabled="addToIsDisabled"></el-input>
            </el-form-item>
            <el-form-item :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'
              " prop="ApprovalCode">
              <el-input v-model="addAuditFlagForm.ApprovalCode" autocomplete="off" :disabled="addToIsDisabled">
                <template slot="prepend" v-if="addAuditType == 1">M-{{
                  code
                    ? code
                    : padding1(this.auditMaterialsInfoList.length + 1, 4)
                }}-{{
                    addAuditFlagForm.ApprovalMajorKey
                      ? addAuditFlagForm.ApprovalMajorKey + "-"
                      : ""
                  }}
                </template>
                <template slot="prepend" v-if="addAuditType == 2">S-{{
                  code ? code : padding1(this.auditImgInfoList.length + 1, 4)
                }}-{{
                    addAuditFlagForm.ApprovalMajorKey
                      ? addAuditFlagForm.ApprovalMajorKey + "-"
                      : ""
                  }}
                </template>
              </el-input>
            </el-form-item>

            <!-- <el-form-item label="报审批次：" prop="ApprovalBatch">
                    第
                    <el-input-number v-model="addAuditFlagForm.ApprovalBatch" :min="1" :precision="0"></el-input-number>
                    批次
                </el-form-item> -->
            <el-form-item label="报审单位：" prop="ReportingUnit">
              <el-input v-model="addAuditFlagForm.ReportingUnit" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="报审人：" prop="ReportingName">
              <el-input v-model="addAuditFlagForm.ReportingName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="报审日期：" prop="ReportingDate">
              <el-date-picker v-model="addAuditFlagForm.ReportingDate" type="datetime" placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <!-- upUrl="/ReviewGO/Review_ApprovalInfo_FAL/OssUploadFileByForm" -->
            <!-- delUrl="/ReviewGO/Review_ApprovalInfo_FAL/DeleteData" -->
            <el-form-item label="关联附件：">
              <UploadValue v-model="addAuditUpFileData" @success="propValidateField" ref="UploadValue" :id="rowId"
                @error="errorValidateField" upUrl="/ReviewGO/Review_ApprovalInfo_FAL/OssUploadFileByForm"
                delUrl="/ReviewGO/Review_ApprovalInfo_FAL/DeleteData">
              </UploadValue>
            </el-form-item>
            <el-form-item label="审批单位或部门：" v-for="(item, index) in SignatoryUnitList" :key="index"
              style="position: relative" required>
              <div style="display: flex">
                <el-select v-model="item.ApprovalOrganName" clearable placeholder="请选择" filterable
                  @change="changeSignatoryUnit($event, item)" default-first-option style="width: 50%">
                  <el-option v-for="v in SignatoryUnitOptions" :key="v.DicName" :label="v.DicName" :value="v.DicName"
                    :disabled="SignatoryUnitOptionsDisabled(item, v)"></el-option>
                </el-select>
                <div style="
                  display: flex;
                  width: 25%;
                  min-width: 280px;
                  margin-left: 10px;
                ">
                  <div style="
                    width: 120px;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: right;
                  ">
                    <span style="color: #f56c6c" v-if="item.ApprovalUserRoleList.length !== 0">* </span>角色：
                  </div>
                  <el-select v-model="item.ApprovalUserRole" clearable :placeholder="item.ApprovalUserRoleList.length === 0
                    ? '暂无角色列表'
                    : '请选择'
                    " :disabled="item.ApprovalUserRoleList.length === 0" filterable default-first-option
                    @change="changeApprovalUserRole($event, item)">
                    <el-option v-for="v in item.ApprovalUserRoleList" :key="v.DicName" :label="v.DicName"
                      :value="v.DicName" :disabled="ApprovalUserRoleDisabled(item, v)"></el-option>
                  </el-select>
                </div>
                <div style="
                  display: flex;
                  min-width: 280px;
                  width: 25%;
                  margin-left: 10px;
                ">
                  <div style="
                    width: 140px;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: right;
                  ">
                    <span style="color: #f56c6c">* </span>审核人：
                  </div>
                  <el-input v-model="item.ApprovalPhone" placeholder="请输入审核人手机号" type="number"></el-input>
                </div>
              </div>

              <div style="
                position: absolute;
                right: -100px;
                top: 2px;
                width: 100px;
                display: flex;
                justify-content: space-around;
              ">
                <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px"
                  @click="addSignatory(index)"></el-button>
                <el-button icon="el-icon-delete" type="primary" size="mini" circle style="font-size: 16px"
                  @click="delSignatory(index, item)" :disabled="SignatoryUnitOptionsLength"></el-button>
              </div>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button @click="addAuditClose">取 消</el-button>
            <el-button type="primary" @click="addAuditSubmit" v-loading="addAuditLoadings">保存</el-button>
          </span>
        </el-dialog>
        <el-dialog :visible.sync="qrcodeVisible" width="50%" append-to-body :show-close="false">
          <div slot="title">
            <el-row style="
              height: 33px;
              text-align: center;
              font-weight: bold;
              font-size: 18px;
            ">
              请将二维码或链接转发至审核人员进行审核
              <img style="
                width: 33px;
                height: 33px;
                margin-right: 10px;
                cursor: pointer;
                float: right;
              " src="../../../assets/delete.png" alt="" @click="qrcodeVisible = false" />
            </el-row>
          </div>
          <div style="
            display: flex;
            justify-content: space-around;
            align-items: center;
          ">
            <div>
              <span>点击链接复制：</span><br />
              <span style="color: #d1100b; cursor: pointer" @click="copyUrl(qrcode)">
                {{ qrcode }}</span>
            </div>
            <div>
              <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="qrcodeVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <el-dialog :visible.sync="ListAuditFlag" width="80%" append-to-body :show-close="false"
          :close="ListAuditFlagClose" custom-class="ViewAudit">
          <div slot="title">
            <el-row style="
              font-size: 18px;
              font-weight: bold;
              color: #606266;
              display: flex;
            ">
              <div style="width: calc(100% - 33px); text-align: center" class="ellipsis1">
                {{ ListAuditForm.ApprovalName }}
              </div>
              <div style="text-align: right; width: 33px">
                <img style="
                  width: 33px;
                  height: 33px;
                  margin-right: 10px;
                  cursor: pointer;
                " src="../../../assets/delete.png" alt="" @click="ListAuditFlagClose" />
              </div>
            </el-row>
            <el-row style="
              font-size: 14px;
              font-weight: bold;
              color: #909399;
              text-align: left;
              width: calc(100% - 33px);
            " class="ellipsis">
              {{ ListAuditForm.ApprovalCode }}
            </el-row>
          </div>

          <div style="height: 73vh; overflow-y: auto">
            <el-collapse v-model="activeNames" @change="activeNamesChange" accordion>
              <el-collapse-item v-for="item in ListAuditData" :key="item.Id" :name="item.Id">
                <template slot="title">
                  <span style="font-weight: bold; font-size: 16px; margin-right: 30px">
                    报审单批次：第{{ item.ApprovalBatch }}批次
                  </span>
                  <span style="font-weight: bold; font-size: 16px">报审日期：{{
                    item.ReportingDate
                      ? item.ReportingDate.substring(0, 19)
                      : ""
                  }}</span>
                </template>
                <el-row :gutter="10" v-loading="item.loading">
                  <el-col :span="9">
                    <el-card v-if="item.viewData.ViewAuditForm">
                      <div style="overflow-y: auto; height: 55vh" class="viewList">
                        <el-descriptions class="margin-top" :column="1" size="medium" border>
                          <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'
                            ">
                            {{ item.viewData.ViewAuditForm.ApprovalName }}
                          </el-descriptions-item>
                          <el-descriptions-item :label="addAuditType == 1
                            ? '设备/材料报审单编号'
                            : '深化图纸报审单编号'
                            ">
                            {{ item.viewData.ViewAuditForm.ApprovalCode }}
                          </el-descriptions-item>
                          <el-descriptions-item label="报审批次">第<span style="color: #d1100b">{{
                            item.viewData.ViewAuditForm.ApprovalBatch
                              }}</span>批次
                          </el-descriptions-item>
                          <el-descriptions-item label="专业">
                            {{ item.viewData.ViewAuditForm.ApprovalMajor }}
                          </el-descriptions-item>

                          <el-descriptions-item label="报审单位">{{
                            item.viewData.ViewAuditForm.ReportingUnit
                          }}</el-descriptions-item>
                          <el-descriptions-item label="报审人">{{
                            item.viewData.ViewAuditForm.ReportingName
                          }}</el-descriptions-item>
                          <el-descriptions-item label="报审日期">
                            {{
                              item.viewData.ViewAuditForm.ReportingDate
                                ? item.viewData.ViewAuditForm.ReportingDate.substring(
                                  0,
                                  19
                                )
                                : ""
                            }}
                          </el-descriptions-item>
                          <el-descriptions-item label="关联附件">
                            <div v-if="item.viewData.ViewUpFileData">
                              <div v-for="val in item.viewData.ViewUpFileData" :key="val.Id" style="
                                color: #d1100b;
                                cursor: pointer;
                                font-size: 14px;
                                font-weight: bold;
                              " @click="downloadFile(val)">
                                {{ val.FileName }}
                              </div>
                            </div>
                          </el-descriptions-item>
                        </el-descriptions>
                      </div>
                    </el-card>
                    <el-empty description="加载中" v-else></el-empty>
                  </el-col>
                  <el-col :span="15">
                    <el-card v-if="item.viewData.ViewSignatoryUnitOptions">
                      <div class="content" style="overflow-y: auto; height: 55vh">
                        <div>
                          <div v-for="(val, ind) in item.viewData
                            .ViewSignatoryUnitOptions" :key="val.Id">
                            <el-form label-width="150px" style="width: 80%; font-weight: bold" class="ViewAuditForm"
                              disabled>
                              <el-form-item :label="val.ApprovalOrganName + '：'">
                                <el-select v-model="val.ApprovalOpinions" clearable placeholder="未选择批复意见"
                                  style="width: 60%">
                                  <el-option v-for="v in ApprovalOpinions" :key="v.DicName" :label="v.DicName"
                                    :value="v.DicValue">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="批注意见：">
                                <el-select v-model="val.ApprovalAnnotation" clearable placeholder="未选择批注意见"
                                  style="width: 60%">
                                  <el-option v-for="v in AnnotationOpinions" :key="v.DicName" :label="v.DicName"
                                    :value="v.DicValue">
                                  </el-option>
                                </el-select>
                                <el-input type="textarea" style="margin-top: 5px" v-model="val.ApprovalARemark">
                                </el-input>
                              </el-form-item>
                              <el-form-item label="关联附件：">
                                <div v-for="c in val.AuditUpFileData" :key="c.Id" style="
                                  color: #d1100b;
                                  cursor: pointer;
                                  font-size: 14px;
                                  font-weight: bold;
                                " @click="downloadFile(c)">
                                  {{ c.FileName }}
                                </div>
                                <div v-if="val.AuditUpFileData?.length == 0" style="
                                  border: 1px solid #d9d9d9;
                                  height: 30px;
                                  line-height: 30px;
                                  width: 40%;
                                  font-size: 12px;
                                  text-align: center;
                                ">
                                  暂无附件
                                </div>
                              </el-form-item>
                              <el-form-item label="角色：">
                                <el-input v-model="val.ApprovalUserRole" placeholder="暂无角色">
                                </el-input>
                              </el-form-item>
                              <el-form-item label="负责人手机号：">
                                <el-input v-model="val.ApprovalPhone" placeholder="负责人手机号">
                                </el-input>
                              </el-form-item>

                              <el-form-item label="负责人签署：">
                                <div
                                  style="display: flex; align-items: center;flex-wrap: wrap;justify-content: space-between;">
                                  <el-image style="
                                    width: 200px;
                                    height: 100px;
                                    border: 1px solid #d9d9d9;margin-bottom: 5px;
                                  " :src="val.ApproverSignature">
                                    <div slot="error" style="
                                      width: 100%;
                                      height: 100%;
                                      text-align: center;
                                      line-height: 100px;
                                      background-color: #f5f7fa;
                                    ">
                                      暂无数据
                                    </div>
                                  </el-image>
                                  <div style="margin-bottom: 5px;">
                                    <el-date-picker v-model="val.ApprovalDateTime" type="date"
                                      value-format="yyyy-MM-dd " format="yyyy-MM-dd " placeholder="未选择日期">
                                    </el-date-picker>
                                  </div>
                                  <div style="margin-bottom: 5px;">
                                    <el-tag v-if="val.ApprovalStatus == 1" type="success">
                                      已审核
                                    </el-tag>
                                    <el-tag v-if="val.ApprovalStatus == 0" type="danger">
                                      未审核或暂存
                                    </el-tag>
                                  </div>
                                </div>
                              </el-form-item>
                            </el-form>
                            <el-divider v-if="
                              ind !=
                              item.viewData.ViewSignatoryUnitOptions.length - 1
                            "></el-divider>
                          </div>
                        </div>
                        <!-- <div v-if="addAuditType == 2">
                                            <div v-for="val in item.viewData.ViewSignatoryUnitOptions" :key="val.value">
                                                <el-form label-width="120px" style="width: 80%;font-weight: bold;"
                                                    class="ViewAuditForm" disabled>

                                                    <el-form-item :label="val.ApprovalOrganName + '：'">
                                                        <div style="display: flex;">
                                                            <el-select v-model="val.ApprovalOpinions" clearable
                                                                placeholder="意见归类"
                                                                style="width: 60%;display: inline-block;" size="small">
                                                                <el-option v-for="v in OpinionClassification"
                                                                    :key="v.DicName" :label="v.DicName"
                                                                    :value="v.DicValue">
                                                                </el-option>
                                                            </el-select>
                                                            <el-input type="textarea"
                                                                style="margin-top: 5px;display: inline-block;"
                                                                placeholder="批注意见备注" :rows="1"
                                                                v-model="val.ApprovalARemark">
                                                            </el-input>
                                                        </div>
                                                    </el-form-item>
                                                    <el-form-item label="关联附件：">
                                                        <div v-for="c in val.AuditUpFileData" :key="c.Id"
                                                            style="color: #D1100B;cursor: pointer;line-height: 1.6;font-size: 14px;font-weight: bold;"
                                                            @click="downloadFile(c)">
                                                            {{ c.FileName }}
                                                        </div>
                                                    </el-form-item>
                                                    <el-form-item label="负责人签署：">
                                                        <div style="display: flex;align-items: center;">
                                                            <el-image style="width: 200px; height: 100px"
                                                                :src="val.ApproverSignature">
                                                                <div slot="error"
                                                                    style="width: 100%;height: 100%;text-align: center;line-height: 100px;background-color: #F5F7FA;">
                                                                    暂无数据
                                                                </div>
                                                            </el-image>

                                                            <div style="margin-left: 20px;">
                                                                <el-tag v-if="val.ApprovalStatus == 1" type="success">
                                                                    已审核
                                                                </el-tag>
                                                                <el-tag v-if="val.ApprovalStatus == 0" type="danger">
                                                                    未审核或暂存
                                                                </el-tag>
                                                            </div>
                                                        </div>
                                                    </el-form-item>

                                                </el-form>
                                                <el-divider></el-divider>
                                            </div>
                                        </div> -->
                      </div>
                    </el-card>
                    <el-empty description="加载中" v-else></el-empty>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="ListAuditFlagClose">取 消</el-button>
          </span>
        </el-dialog>
        <!-- //PC消息列表 -->
        <el-drawer title="消息" :visible.sync="drawerVisible" :before-close="handleClose" size="600px">
          <div style="width: 100%;padding: 0 20px;">
            <div
              style="display: flex;align-items: center;justify-content: space-between;;line-height: 24px;padding: 10px 5px;border-bottom:1px solid #ccc;margin-bottom: 10px;">
              <span style="font-size: 14px;">当前手机号：{{ RGApprovalUnitUserInfo.Phone }}</span>
              <el-button :style="`padding: 5px;color:${msgCount != 0 ? '#3370ff' : ''}`" type="text" @click="UPSendMsg"
                :disabled="msgCount == 0">全部设为已读</el-button>
            </div>
          </div>
          <div style="height: calc(100vh - 186px); overflow-y: auto; width: 100%;padding: 0 20px;"
            v-loading="loadingMsg">
            <div v-for="item in SendMsgList" :key="item.Id"
              style="position: relative;margin-top: 5px;margin-bottom: 5px;" @click="clickSendMsg(item)">
              <el-card>
                <span style="font-size: 14px;line-height: 1.6;"> {{ item.MsgInfo
                  }}</span>
                <el-tag size="small" type="success" style="position: absolute;right: 0px;top: 0px;"
                  v-if="item.IsBeenRead">已读</el-tag>
                <el-tag size="small" style="position: absolute;right: 0px;top: 0px;" v-if="!item.IsBeenRead">未读</el-tag>
              </el-card>
            </div>
          </div>
          <el-pagination layout="prev, pager, next,total" :total="pagination.total" small background
            :page-size="pagination.pageSize" :page-count="pagination.pageCount" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            style="height: 40px;line-height: 40px;width: 100%;text-align: center;margin-top: 10px;">
          </el-pagination>
        </el-drawer>
      </div>
      <div v-else>
        <el-dialog :visible.sync="addAuditFlag" width="100%" append-to-body :show-close="false" :close="addAuditClose"
          custom-class="H5dialog">
          <div slot="title">
            <div style="
              line-height: 20px;
              display: flex;
              justify-content: space-between;
            ">
              <span style="font-size: 20px; font-weight: bold; color: #606266">{{
                addAuditType == 1
                  ? `${AuditTitle}设备/材料报审`
                  : `${AuditTitle}深化图纸报审`
              }}</span>

              <img style="
                width: 20px;
                height: 20px;
                margin-right: 10px;
                cursor: pointer;
              " src="../../../assets/delete.png" alt="" @click="addAuditClose" />
            </div>
          </div>
          <div style="
            overflow-y: auto;
            height: calc(100vh - 120px);
            width: 100%;
            overflow-x: hidden;
          ">
            <el-form :model="addAuditFlagForm" :rules="addAuditFlagRules" ref="addAuditFlagForm"
              style="font-weight: bold; padding: 10px" class="addAuditForm" v-loading.fullscreen.lock="addAuditLoadings"
              label-position="top" size="small">
              <el-form-item label="项目名称：">
                <el-input v-model="TaskData.ProjectName" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="任务名称：">
                <el-input v-model="TaskData.TaskName" autocomplete="off" disabled></el-input>
              </el-form-item>

              <el-form-item label="专业：" prop="ApprovalMajor">
                <el-select v-model="addAuditFlagForm.ApprovalMajor" clearable placeholder="请选择" filterable
                  @change="changeApprovalMajor" default-first-option>
                  <el-option v-for="v in ApprovalMajorList" :key="v.DicName" :label="v.DicName"
                    :value="v.DicName"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'" prop="ApprovalName">
                <el-input v-model="addAuditFlagForm.ApprovalName" autocomplete="off" @input="changeApprovalName"
                  :disabled="addToIsDisabled"></el-input>
              </el-form-item>
              <el-form-item :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'
                " prop="ApprovalCode">
                <el-input v-model="addAuditFlagForm.ApprovalCode" autocomplete="off" :disabled="addToIsDisabled">
                  <template slot="prepend" v-if="addAuditType == 1">M-{{
                    code
                      ? code
                      : padding1(this.auditMaterialsInfoList.length + 1, 4)
                  }}-{{
                      addAuditFlagForm.ApprovalMajorKey
                        ? addAuditFlagForm.ApprovalMajorKey + "-"
                        : ""
                    }}
                  </template>
                  <template slot="prepend" v-if="addAuditType == 2">S-{{
                    code ? code : padding1(this.auditImgInfoList.length + 1, 4)
                  }}-{{
                      addAuditFlagForm.ApprovalMajorKey
                        ? addAuditFlagForm.ApprovalMajorKey + "-"
                        : ""
                    }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="报审单位：" prop="ReportingUnit">
                <el-input v-model="addAuditFlagForm.ReportingUnit" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="报审人：" prop="ReportingName">
                <el-input v-model="addAuditFlagForm.ReportingName" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="报审日期：" prop="ReportingDate">
                <el-date-picker v-model="addAuditFlagForm.ReportingDate" type="datetime" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <!-- upUrl="/ReviewGO/Review_ApprovalInfo_FAL/OssUploadFileByForm" -->
              <!-- delUrl="/ReviewGO/Review_ApprovalInfo_FAL/DeleteData" -->
              <el-form-item label="关联附件：">
                <UploadValue v-model="addAuditUpFileData" @success="propValidateField" ref="UploadValue" :id="rowId"
                  @error="errorValidateField" upUrl="/ReviewGO/Review_ApprovalInfo_FAL/OssUploadFileByForm"
                  delUrl="/ReviewGO/Review_ApprovalInfo_FAL/DeleteData">
                </UploadValue>
              </el-form-item>
              <div v-for="(item, index) in SignatoryUnitList" :key="index"
                style="border: 1px solid #e0e0e0; margin-top: 10px; padding: 10px">
                <el-form-item label="审批单位或部门：" style="position: relative" required>
                  <el-select v-model="item.ApprovalOrganName" clearable placeholder="请选择" filterable
                    @change="changeSignatoryUnit($event, item)" default-first-option style="width: 100%">
                    <el-option v-for="v in SignatoryUnitOptions" :key="v.DicName" :label="v.DicName" :value="v.DicName"
                      :disabled="SignatoryUnitOptionsDisabled(item, v)"></el-option>
                  </el-select>
                  <div>
                    <div style="
                      width: 120px;
                      font-size: 18px;
                      font-weight: bold;
                      text-align: left;
                      margin-top: 10px;
                      margin-bottom: 10px;
                    ">
                      <span style="color: #f56c6c" v-if="item.ApprovalUserRoleList.length !== 0">* </span>角色：
                    </div>
                    <el-select v-model="item.ApprovalUserRole" clearable :placeholder="item.ApprovalUserRoleList.length === 0
                      ? '暂无角色列表'
                      : '请选择'
                      " :disabled="item.ApprovalUserRoleList.length === 0" filterable default-first-option
                      @change="changeApprovalUserRole($event, item)">
                      <el-option v-for="v in item.ApprovalUserRoleList" :key="v.DicName" :label="v.DicName"
                        :value="v.DicName" :disabled="ApprovalUserRoleDisabled(item, v)"></el-option>
                    </el-select>
                  </div>
                  <div>
                    <div style="
                      width: 140px;
                      font-size: 18px;
                      font-weight: bold;
                      text-align: left;
                      margin-top: 10px;
                      margin-bottom: 10px;
                    ">
                      <span style="color: #f56c6c">* </span>审核人：
                    </div>
                    <el-input v-model="item.ApprovalPhone" placeholder="请输入审核人手机号" type="number"></el-input>
                  </div>
                </el-form-item>
                <div style="width: 100%; text-align: right">
                  <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px"
                    @click="addSignatory(index)"></el-button>
                  <el-button icon="el-icon-delete" type="primary" size="mini" circle style="font-size: 16px"
                    @click="delSignatory(index, item)" :disabled="SignatoryUnitOptionsLength"></el-button>
                </div>
              </div>
            </el-form>
          </div>
          <div style="
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 10px;
            padding: 10px;
          ">
            <el-button type="primary" @click="addAuditSubmit" v-loading="addAuditLoadings" size="small">保存</el-button>
            <el-button @click="addAuditClose" size="small">取 消</el-button>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="qrcodeVisible" width="100%" append-to-body :show-close="false"
          custom-class="H5dialog">
          <div slot="title">
            <div style="
              line-height: 20px;
              display: flex;
              justify-content: space-between;
            ">
              <span style="font-size: 20px; font-weight: bold; color: #606266">请将二维码或链接转发至审核人员进行审核</span>

              <img style="
                width: 20px;
                height: 20px;
                margin-right: 10px;
                cursor: pointer;
              " src="../../../assets/delete.png" alt="" @click="qrcodeVisible = false" />
            </div>
          </div>
          <div style="
            overflow-y: auto;
            height: calc(100vh - 120px);
            width: 100%;
            overflow-x: hidden;
            position: relative;
          ">
            <div style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            ">
              <div style="text-align: center">
                <span>点击链接复制：</span><br />
                <span style="color: #d1100b; cursor: pointer" @click="copyUrl(qrcode)">
                  {{ qrcode }}</span>
              </div>
              <div style="text-align: center">
                <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
              </div>
            </div>
          </div>
          <div>
            <el-button @click="qrcodeVisible = false" size="small">关闭</el-button>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="ListAuditFlag" width="100%" append-to-body :show-close="false"
          :close="ListAuditFlagClose" custom-class="ViewAudit H5dialog">
          <div slot="title">
            <el-row style="
              font-size: 18px;
              font-weight: bold;
              color: #606266;
              display: flex;
            ">
              <div style="width: calc(100% - 20px); text-align: center" class="ellipsis1">
                {{ ListAuditForm.ApprovalName }}
              </div>
              <div style="text-align: right; width: 20px">
                <img style="
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                  cursor: pointer;
                " src="../../../assets/delete.png" alt="" @click="ListAuditFlagClose" />
              </div>
            </el-row>
            <el-row style="
              font-size: 14px;
              font-weight: bold;
              color: #909399;
              text-align: left;
              width: calc(100% - 33px);
            " class="ellipsis">
              {{ ListAuditForm.ApprovalCode }}
            </el-row>
          </div>

          <div style="
            overflow-y: auto;
            height: calc(100vh - 120px);
            width: 100%;
            overflow-x: hidden;
            position: relative;
          ">
            <el-collapse v-model="activeNames" @change="activeNamesChange" accordion>
              <el-collapse-item v-for="item in ListAuditData" :key="item.Id" :name="item.Id">
                <template slot="title">
                  <div style="display: flex; align-items: center; flex-wrap: wrap">
                    <div style="
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 1.2;
                      width: 100%;
                    ">
                      报审单批次：第{{ item.ApprovalBatch }}批次
                    </div>
                    <div style="
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 1.2;
                      width: 100%;
                    ">
                      报审日期：{{
                        item.ReportingDate
                          ? item.ReportingDate.substring(0, 19)
                          : ""
                      }}
                    </div>
                  </div>
                </template>
                <el-row :gutter="10" v-loading="item.loading">
                  <div style="padding: 5px">
                    <el-card v-if="item.viewData.ViewAuditForm" :body-style="{ padding: '5px' }">
                      <div class="viewList">
                        <el-descriptions class="margin-top" :column="1" size="small" border>
                          <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'
                            ">
                            {{ item.viewData.ViewAuditForm.ApprovalName }}
                          </el-descriptions-item>
                          <el-descriptions-item :label="addAuditType == 1
                            ? '设备/材料报审单编号'
                            : '深化图纸报审单编号'
                            ">
                            {{ item.viewData.ViewAuditForm.ApprovalCode }}
                          </el-descriptions-item>
                          <el-descriptions-item label="报审批次">第<span style="color: #d1100b">{{
                            item.viewData.ViewAuditForm.ApprovalBatch
                              }}</span>批次
                          </el-descriptions-item>
                          <el-descriptions-item label="专业">
                            {{ item.viewData.ViewAuditForm.ApprovalMajor }}
                          </el-descriptions-item>

                          <el-descriptions-item label="报审单位">{{
                            item.viewData.ViewAuditForm.ReportingUnit
                          }}</el-descriptions-item>
                          <el-descriptions-item label="报审人">{{
                            item.viewData.ViewAuditForm.ReportingName
                          }}</el-descriptions-item>
                          <el-descriptions-item label="报审日期">
                            {{
                              item.viewData.ViewAuditForm.ReportingDate
                                ? item.viewData.ViewAuditForm.ReportingDate.substring(
                                  0,
                                  19
                                )
                                : ""
                            }}
                          </el-descriptions-item>
                          <el-descriptions-item label="关联附件">
                            <div v-if="item.viewData.ViewUpFileData">
                              <div v-for="val in item.viewData.ViewUpFileData" :key="val.Id" style="
                                color: #d1100b;
                                cursor: pointer;
                                font-size: 14px;
                                font-weight: bold;
                              " @click="downloadFile(val)">
                                {{ val.FileName }}
                              </div>
                            </div>
                          </el-descriptions-item>
                        </el-descriptions>
                      </div>
                    </el-card>
                    <el-empty description="加载中" v-else></el-empty>
                  </div>
                  <div style="padding: 5px">
                    <el-card v-if="item.viewData.ViewSignatoryUnitOptions">
                      <div class="content" style="overflow-y: auto">
                        <div>
                          <div v-for="(val, ind) in item.viewData
                            .ViewSignatoryUnitOptions" :key="val.Id">
                            <el-form style="font-weight: bold" class="ViewAuditForm" disabled label-position="top"
                              size="mini">
                              <el-form-item :label="val.ApprovalOrganName + '：'">
                                <el-select v-model="val.ApprovalOpinions" clearable placeholder="未选择批复意见">
                                  <el-option v-for="v in ApprovalOpinions" :key="v.DicName" :label="v.DicName"
                                    :value="v.DicValue">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="批注意见：">
                                <el-select v-model="val.ApprovalAnnotation" clearable placeholder="未选择批注意见">
                                  <el-option v-for="v in AnnotationOpinions" :key="v.DicName" :label="v.DicName"
                                    :value="v.DicValue">
                                  </el-option>
                                </el-select>
                                <el-input type="textarea" style="margin-top: 5px" v-model="val.ApprovalARemark">
                                </el-input>
                              </el-form-item>
                              <el-form-item :label="'关联附件：'">
                                <div v-for="c in val.AuditUpFileData" :key="c.Id" style="
                                  color: #d1100b;
                                  cursor: pointer;
                                  font-size: 14px;
                                  font-weight: bold;
                                " @click="downloadFile(c)">
                                  {{ c.FileName }}
                                </div>
                                <div v-if="val.AuditUpFileData?.length == 0" style="
                                  border: 1px solid #d9d9d9;
                                  height: 30px;
                                  width: 40%;
                                  font-size: 12px;
                                  text-align: center;
                                ">
                                  暂无附件
                                </div>
                              </el-form-item>
                              <el-form-item label="角色：">
                                <el-input v-model="val.ApprovalUserRole" placeholder="暂无角色">
                                </el-input>
                              </el-form-item>
                              <el-form-item label="负责人手机号：">
                                <el-input v-model="val.ApprovalPhone" placeholder="负责人手机号">
                                </el-input>
                              </el-form-item>

                              <el-form-item label="负责人签署：">
                                <div style="">
                                  <el-image style="
                                    width: 200px;
                                    height: 100px;
                                    border: 1px solid #d9d9d9;
                                  " :src="val.ApproverSignature">
                                    <div slot="error" style="
                                      width: 100%;
                                      height: 100%;
                                      text-align: center;
                                      line-height: 100px;
                                      background-color: #f5f7fa;
                                    ">
                                      暂无数据
                                    </div>
                                  </el-image>
                                  <div>
                                    <el-date-picker v-model="val.ApprovalDateTime" type="date"
                                      value-format="yyyy-MM-dd " format="yyyy-MM-dd " placeholder="未选择日期">
                                    </el-date-picker>
                                  </div>
                                  <div style="margin-top: 10px">
                                    <el-tag v-if="val.ApprovalStatus == 1" type="success">
                                      已审核
                                    </el-tag>
                                    <el-tag v-if="val.ApprovalStatus == 0" type="danger">
                                      未审核或暂存
                                    </el-tag>
                                  </div>
                                </div>
                              </el-form-item>
                            </el-form>
                            <el-divider v-if="
                              ind !=
                              item.viewData.ViewSignatoryUnitOptions.length - 1
                            "></el-divider>
                          </div>
                        </div>
                      </div>
                    </el-card>
                    <el-empty description="加载中" v-else></el-empty>
                  </div>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </div>

          <div style="
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 5px;
          ">
            <el-button @click="ListAuditFlagClose" size="small">取 消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import UploadValue from "@/components/UploadValue/UploadValue.vue";
import moment from "moment";
import vueQr from "vue-qr";

export default {
  data() {
    return {
      activeName: "1",
      loadingTask: false,
      loadingLeft: false,
      loadingRight: false,
      moment,
      qrcode: "",
      ViewAuditFlag: false,
      ViewAuditLoadings: false,
      ViewAuditForm: {},
      ViewUpFileData: [],
      ViewSignatoryUnitOptions: [],
      qrcodeVisible: false,
      code: "", //报审编号
      ApprovalUnit: {},
      RGApprovalUnitUserInfo: {},
      TaskData: {},
      TaskId: "",
      rowId: "",
      addAuditLoadings: false,
      addAuditFlag: false,
      addIsDisabled: false,
      addAuditFlagForm: {
        TaskId: "",
        ApprovalType: "",
        ApprovalName: "",
        ApprovalCode: "",
        ApprovalMajor: "",
        ApprovalMajorKey: "",
        // ApprovalBatch: "",
        ReportingName: "",
        ReportingDate: "",
        ReportingUnit: "",
        SignatoryUnit: "",
      },
      addAuditUpFileData: [],
      SignatoryUnitList: [],
      addAuditFlagRules: {
        ApprovalName: [
          { required: true, message: "未输入名称", trigger: "blur" },
        ],
        ApprovalCode: [
          { required: true, message: "未输入编号", trigger: "blur" },
        ],
        ApprovalBatch: [
          { required: true, message: "未输入批次", trigger: "change" },
        ],
        ReportingUnit: [
          { required: true, message: "未输入报审单位", trigger: "blur" },
        ],
        ApprovalMajor: [
          { required: true, message: "未选择专业", trigger: "change" },
        ],
        ReportingDate: [
          { required: true, message: "未选择报审日期", trigger: "change" },
        ],
        // SignatoryUnit: [
        //   { required: true, message: "请选择签署机构", trigger: "change" },
        // ],
        ReportingName: [
          { required: true, message: "未输入报审人", trigger: "change" },
        ],
        // addAuditUpFileData: [
        //   { required: true, message: "请上传附件", trigger: "blur" },
        // ],
      },
      AuditTitle: "",
      addAuditType: 0, //1设备材料，2深化图纸
      ApprovalOpinions: [],
      AnnotationOpinions: [],
      OpinionClassification: [],
      SignatoryUnitOptions: [],
      auditMaterialsInfoList: [],
      auditImgInfoList: [],
      //列表弹窗
      ListAuditFlag: false,
      ListAuditForm: {},
      ListAuditData: [],
      activeNames: "",
      //追加禁用
      addToIsDisabled: false,
      //报审专业字典列表
      ApprovalMajorList: [],
      //报审 角色列表
      ApprovalUserRoleList: [],
      //禁用部门列表
      SignatoryUniDisabledList: [],
      ApprovalUserRoleDisabledList: [],
      isPCH5Text: "",
      //消息通知弹出框
      drawerVisible: false,
      SendMsgList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      msgCount: 0,
      loadingMsg: false,
    };
  },
  props: {},
  components: { UploadValue, vueQr },
  methods: {
    GetMsgUnreadCount() {
      this.$http.post('/ReviewGO/Review_ApprovalMsg/GetMsgUnreadCount').then(res => {
        if (res.Success) {
          this.msgCount = res.Data || 0
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    UPSendMsg() {

      this.$http.post('/ReviewGO/Review_ApprovalMsg/UpdateReadingStatusAll').then(res => {
        if (res.Success) {
          this.getSendMsg()
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.getSendMsg()

    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getSendMsg()
    },
    clickSendMsg(item) {
      if (item.IsBeenRead) {
        return
      }
      this.$http.post('/ReviewGO/Review_ApprovalMsg/UpdateReadingStatus', {
        id: item.Id
      }).then(res => {
        if (res.Success) {
          this.getSendMsg()
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    //获取通知消息列表
    getSendMsg() {
      this.GetMsgUnreadCount()
      this.loadingMsg = true;
      this.$http
        .post("/ReviewGO/Review_ApprovalMsg/GetDataListByMoreCondition", {
          PageIndex: this.pagination.currentPage,
          PageRows: this.pagination.pageSize,
          SortField: "CreateTime",
          SortType: "desc",
          Search: [
            {
              Condition: "EU_Id",
              Keyword: this.RGApprovalUnitUserInfo.Id,
            },

          ],
        })
        .then(res => {
          this.loadingMsg = false;
          if (res.Success) {
            this.SendMsgList = res.Data || []
            this.pagination.total = res.Total;

          }
        })
    },
    handleClose() {
      this.drawerVisible = false
    },
    onPopState(e) {
      // 监听到浏览器回退事件（这里提示用的confirm，是可以自定义的）
      if (confirm('离开当前页面将退出登录，您确定离开当前页面吗？')) {
        // 点击确定回退
        window.removeEventListener('popstate', this.onPopState)
        window.history.go(-1)
      } else {
        // 点击取消不回退
        window.history.pushState(null, null, window.location.href)
      }
    },
    changeApprovalMajor(val) {
      console.log(val);
      let obj = this.ApprovalMajorList.find((item) => {
        return item.DicName == val;
      });
      console.log(obj);
      this.$set(this.addAuditFlagForm, "ApprovalMajorKey", obj?.DicValue || "");
    },
    activeNamesChange(a) {
      let objIndex = this.ListAuditData.findIndex((i) => i.Id == a);
      if (objIndex != -1) {
        if (!this.ListAuditData[objIndex].viewData.ViewAuditForm) {
          this.getViewAuditDataInfo(this.ListAuditData[objIndex]);
        }
      }
    },
    getListAuditData(row) {
      this.$http
        .post("/ReviewGO/Review_ApprovalInfo/GetDataListByMoreCondition", {
          Search: [
            {
              Condition: "TaskId",
              Keyword: row.TaskId,
            },
            {
              Condition: "ApprovalCode",
              Keyword: row.ApprovalCode,
            },
          ],
        })
        .then((res) => {
          if (res.Success) {
            this.ListAuditForm = { ...row };
            if (res.Data && res.Data.length > 0) {
              res.Data.forEach((item) => {
                this.$set(item, "viewData", {});
                this.$set(item, "loading", false);
              });
              this.ListAuditData = res.Data;
              this.activeNames =
                this.ListAuditData[this.ListAuditData.length - 1].Id;
              this.activeNamesChange(
                this.ListAuditData[this.ListAuditData.length - 1].Id
              );
            }
          }
        });
    },
    ListAuditFlagClose() {
      this.ListAuditFlag = false;
    },
    copyUrl(text) {
      var input = document.createElement("input"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      if (this.isPCH5Text === "PC") {
        this.$message.success("复制成功！");
      } else {
        this.$phoneMessage({
          message: "复制成功！",
        });
      }
    },
    getTaskData() {
      this.loadingTask = true;
      this.$http
        .post("/Base_TaskWork/Base_Task/GetBaseTaskForReviewGo", {
          id: this.TaskId,
        })
        .then((res) => {
          this.loadingTask = false;
          if (res.Success) {
            this.TaskData = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    //附件上传成功后避免组件验证失效
    propValidateField() {
      this.SubSignatoryUnitList();
      setTimeout(() => {
        this.$refs["addAuditFlagForm"].validateField("addAuditUpFileData");
      }, 100);
    },
    errorValidateField(data) {
      console.log(data, "errorValidateField");
      let str = "";
      data.forEach((item) => {
        str = item.name + "," + str;
      });
      this.addAuditLoadings = false;
      if (this.isPCH5Text === "PC") {
        this.$message.error(str + "文件上传失败");
      } else {
        this.$phoneMessage({
          message: str + "文件上传失败",
        });
      }
    },
    // 新增保存
    async addAuditSubmit() {
      // console.log(flag)
      // console.log(this.addAuditUpFileData[0], '2')
      console.log(this.addAuditFlagForm);

      this.$refs.addAuditFlagForm.validate((valid) => {
        if (valid) {
          let arr = [];
          this.SignatoryUnitList.forEach((item) => {
            if (
              !(
                item.ApprovalOrganName &&
                (item.ApprovalUserType === 0 || item.ApprovalUserType === 1) &&
                /^1[3-9]\d{9}$/.test(item.ApprovalPhone)
              )
            ) {
              arr.push(item);
            } else if (item.ApprovalUserRoleList.length > 0) {
              if (!item.ApprovalUserRole) {
                arr.push(item);
              }
            }
          });
          if (arr.length > 0) {
            if (this.isPCH5Text === "PC") {
              this.$message.error("请填写正确的签署机构信息");
            } else {
              this.$phoneMessage({
                message: "请填写正确的签署机构信息",
              });
            }
            return
          }
          // let flag = this.SignatoryUnitList.every(item => {
          //     return item.ApprovalOrganName && (item.ApprovalUserType === 0 || item.ApprovalUserType === 1) && (/^1[3-9]\d{9}$/.test(item.ApprovalPhone))
          // })
          // if (!flag) {
          //     return this.$message.error('请填写正确的签署机构信息')
          // }

          this.addAuditLoadings = true;
          let obj = JSON.parse(JSON.stringify(this.addAuditFlagForm));
          if (this.addAuditType == 1) {
            obj.ApprovalCode = `M-${this.code
              ? this.code
              : this.padding1(this.auditMaterialsInfoList.length + 1, 4)
              }-${obj.ApprovalMajorKey ? obj.ApprovalMajorKey + "-" : ""}${obj.ApprovalCode
              }`;
          } else if (this.addAuditType == 2) {
            obj.ApprovalCode = `S-${this.code
              ? this.code
              : this.padding1(this.auditImgInfoList.length + 1, 4)
              }-${obj.ApprovalMajorKey ? obj.ApprovalMajorKey + "-" : ""}${obj.ApprovalCode
              }`;
          }

          this.$http
            .post("/ReviewGO/Review_ApprovalInfo/SaveData", {
              ...obj,
              ApprovalType: this.addAuditType,
              TaskId: this.TaskId,
            })
            .then(async (res) => {
              if (res.Success) {
                let data = res.Data;
                this.rowId = data.Id;
                this.code = res.Data.ApprovalCode.split("-")[1];
                res.Data.ApprovalCode = this.sliceStringAfterThirdValue(
                  res.Data.ApprovalCode,
                  "-"
                );
                this.addAuditFlagForm = { ...res.Data };
                await setTimeout(async () => {
                  let flag = await this.$refs.UploadValue.upload();
                  console.log(flag, "flag");
                  if (!flag) {
                    this.SubSignatoryUnitList();
                  }
                });
              } else {
                this.$message.error("新建报审信息失败");
                this.addAuditLoadings = false;
              }
            });
        } else {
          if (this.isPCH5Text === "PC") {
            this.$message.error("请填写必填项信息");
          } else {
            this.$phoneMessage({
              message: "请填写必填项信息",
            });
          }
          return false;
        }
      });
    },
    //图审新建添加
    addSignatory(index) {
      this.SignatoryUnitList.splice(index + 1, 0, {
        ApprovalId: "",
        ApprovalOrganKey: "",
        ApprovalOrganName: "",
        ApprovalUserType: 1,
        ApprovalPhone: "",
        ApprovalUserRole: "",
        ApprovalUserRoleKey: "",
        ApprovalUserRoleList: [],
      });
    },
    delSignatory(index, item) {
      console.log(item, index);

      if (item.Id) {
        this.$confirm("此操作将删除该信息,确认删除吗", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          customClass: this.isPCH5Text === "PC" ? "" : "h5-confirm-class",
        }).then(() => {
          this.$http
            .post("/ReviewGO/Review_ApprovalSignInfo/DeleteData", [item.Id])
            .then((res) => {
              if (res.Success) {
                if (this.isPCH5Text === "PC") {
                  this.$message.success("删除成功");
                } else {
                  this.$phoneMessage({
                    message: "删除成功",
                  });
                }

                this.SignatoryUnitList.splice(index, 1);
              } else {
                this.$message.error(res.Msg);
              }
            });
        });
      } else {
        this.SignatoryUnitList.splice(index, 1);
      }
    },
    addAuditUpSuccess(FileList) {
      this.addAuditUpFileData = [...FileList];
      console.log(this.addAuditUpFileData, "11");
    },
    addAuditUpRemove(FileList, newFileListData) {
      this.addAuditUpFileData = [...FileList];
      console.log(FileList, newFileListData);
      console.log(this.addAuditUpFileData, "1122");
    },
    addAuditClose() {
      this.addAuditFlag = false;
    },
    addAuditOpen(num, item, isTrue, text) {
      this.addAuditLoadings = false;
      if (item) {
        this.AuditTitle = "编辑";
      } else {
        this.AuditTitle = "新建";
      }

      if (isTrue) {
        this.AuditTitle = "查看";
        // this.addIsDisabled = true
      } else {
        // this.addIsDisabled = false
      }
      if (text === "追加") {
        this.AuditTitle = "追加";
        this.addToIsDisabled = true;
      } else {
        this.addToIsDisabled = false;
      }
      console.log(2);
      this.addAuditType = num;
      this.addAuditFlag = true;
      this.addAuditUpFileData = [];
      this.rowId = "";
      this.code = "";
      this.addAuditFlagForm = {
        TaskId: "",
        ApprovalType: "",
        ApprovalName: "",
        ApprovalCode: "",
        ApprovalMajor: "",
        ApprovalMajorKey: "",
        // ApprovalBatch: "",
        ReportingName: this.RGApprovalUnitUserInfo.RealName,
        ReportingDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        ReportingUnit: this.ApprovalUnit.ReportingUnit,
        SignatoryUnit: "",
      };
      this.addAuditLoadings = false;
      this.SignatoryUnitList = [
        {
          ApprovalId: "",
          ApprovalOrganKey: "",
          ApprovalOrganName: "",
          ApprovalUserType: 1,
          ApprovalPhone: "",
          ApprovalUserRole: "",
          ApprovalUserRoleKey: "",
          ApprovalUserRoleList: [],
        },
      ];
      this.SignatoryUniDisabledList = [];
      this.ApprovalUserRoleDisabledList = [];
      this.$nextTick(() => {
        this.$refs.addAuditFlagForm.resetFields();
        if (item) {
          if (text == "追加") {
            this.addAuditFlagForm = { ...item };
            this.addAuditFlagForm.Id = "";
            (this.addAuditFlagForm.ReportingDate = moment().format(
              "YYYY-MM-DD HH:mm:ss"
            )),
              (this.addAuditFlagForm.Status = 0);
            delete this.addAuditFlagForm.ApprovalBatch;
            this.code = this.addAuditFlagForm.ApprovalCode.split("-")[1];
            this.addAuditFlagForm.ApprovalCode =
              this.sliceStringAfterThirdValue(
                this.addAuditFlagForm.ApprovalCode,
                "-"
              );
            return;
          }
          this.getApprovalInfo(item, text);
        }
      });
    },
    //图审签署保存
    SubSignatoryUnitList() {
      let arr = [...this.SignatoryUnitList];
      arr.forEach((item) => {
        item.ApprovalId = this.rowId;
        // item.ApprovalOrganKey = item.ApprovalOrganKey
      });
      this.$http
        .post("/ReviewGO/Review_ApprovalSignInfo/SaveDataByList", arr)
        .then((res) => {
          this.addAuditFlag = false;
          this.addAuditLoadings = false;
          if (res.Success) {
            if (this.isPCH5Text === "PC") {
              this.$message.success("保存报审信息成功");
            } else {
              this.$phoneMessage({
                message: "保存报审信息成功",
              });
            }
            if (this.addAuditType == 1) {
              this.getDataListByMoreCondition(1);
            } else if (this.addAuditType == 2) {
              this.getDataListByMoreCondition(2);
            }
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    sliceStringAfterThirdValue(str, value) {
      // 获取第一个值的位置
      var index1 = str.indexOf(value);
      // 如果第一个值不存在，返回原字符串
      if (index1 === -1) return str;

      // 获取第二个值的位置
      var index2 = str.indexOf(value, index1 + value.length);
      // 如果第二个值不存在，返回原字符串
      if (index2 === -1) return str;

      // 获取第三个值的位置
      var index3 = str.indexOf(value, index2 + value.length);
      // 如果第三个值不存在，返回从第二个值之后的部分
      if (index3 === -1) return str.substring(index2 + value.length);

      // 截取从第三个值结束到字符串末尾的部分
      return str.substring(index3 + value.length);
    },

    //图审编辑详情
    async getApprovalInfo(item) {
      this.addAuditLoadings = true;
      //主要信息详情
      let a = () => {
        return this.$http
          .post("/ReviewGO/Review_ApprovalInfo/GetTheData", {
            id: item.Id,
          })
          .then((res) => {
            if (res.Success) {
              // console.log(this.sliceStringAfterThirdValue(res.Data.ApprovalCode, '-'), '-------------')
              this.code = res.Data.ApprovalCode.split("-")[1];

              res.Data.ApprovalCode = this.sliceStringAfterThirdValue(
                res.Data.ApprovalCode,
                "-"
              );

              this.addAuditFlagForm = { ...res.Data };
            }
          });
      };
      //附件详情
      let b = () => {
        return this.$http
          .post(
            "/ReviewGO/Review_ApprovalInfo_FAL/GetDataListBySingleCondition",
            {
              Search: {
                Condition: "ApprovalId",
                Keyword: item.Id,
              },
            }
          )
          .then((res) => {
            if (res.Success) {
              res.Data.length;
              if (res.Data.length > 0 && res.Data) {
                res.Data.forEach((item) => {
                  item.name = item.FileName;
                  item.size = item.FileSize;
                  item.url = item.FileWebURL;
                  item.uid = item.Id;
                  item.status = "success";
                });
                this.addAuditUpFileData = [...res.Data];
              } else {
                this.addAuditUpFileData = [];
              }
            }
          });
      };
      //签署机构详情
      let c = () => {
        this.$http
          .post(
            "/ReviewGO/Review_ApprovalSignInfo/GetDataListBySingleCondition",
            {
              Search: {
                Condition: "ApprovalId",
                Keyword: item.Id,
              },
            }
          )
          .then((res) => {
            if (res.Success) {
              if (res.Success) {
                res.Data.forEach((item) => {
                  item.vid = new Date().getTime();
                });
                if (res.Data.length > 0) {
                  this.SignatoryUnitList = [...res.Data];
                  this.SignatoryUnitList.forEach((item) => {
                    let obj = this.SignatoryUnitOptions.find(
                      (val) => val.DicName === item.ApprovalOrganName
                    );
                    this.$set(
                      item,
                      "ApprovalUserRoleList",
                      obj?.DictionaryDetailsList || []
                    );

                    this.ApprovalUserRoleDisabledList.push(
                      item.ApprovalUserRoleKey
                    );
                    this.SignatoryUniDisabledList.push(item.ApprovalOrganKey);
                  });

                  console.log(
                    this.SignatoryUnitList,
                    "console.log(this.SignatoryUnitList)console.log(this.SignatoryUnitList)"
                  );
                } else {
                  this.SignatoryUnitList = [
                    {
                      ApprovalId: "",
                      ApprovalOrganKey: "",
                      ApprovalOrganName: "",
                      ApprovalUserType: 1,
                      ApprovalPhone: "",
                      ApprovalUserRole: "",
                      ApprovalUserRoleKey: "",
                      ApprovalUserRoleList: [],
                    },
                  ];
                }
              }
            }
          });
      };
      Promise.all([a(), b(), c()])
        .then(() => {
          this.addAuditLoadings = false;
        })
        .catch(() => {
          this.addAuditLoadings = false;
        });
    },

    // 图审字典 	批复意见-内 1 材料 2 图纸
    getApprovalOpinions(type) {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword:
            type == 1 ? "RG_ApprovalOpinions_CL" : "RG_ApprovalOpinions_TS",
        })
        .then((res) => {
          if (res.Success) {
            this.ApprovalOpinions = res.Data.DictionaryDetailsList || [];
          }
        });
    },
    // 图审字典 批注意见-内 1 材料 2 图纸
    getAnnotationOpinions(type) {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword:
            type == 1 ? "RG_AnnotationOpinions_CL" : "RG_AnnotationOpinions_TS",
        })
        .then((res) => {
          if (res.Success) {
            this.AnnotationOpinions = res.Data.DictionaryDetailsList || [];
          }
        });
    },
    // 图审字典
    // getOpinionClassification() {
    //     this.$http
    //         .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
    //             Condition: "DicKey",
    //             Keyword: 'ZS_OpinionClassification_TS_N',
    //         }).then(res => {
    //             if (res.Success) {
    //                 this.OpinionClassification = res.Data.DictionaryDetailsList || []
    //             }
    //         })
    // },
    clearSignatoryUnit(item) {
      console.log(item);
    },
    changeApprovalUserRole(val, item) {
      this.SignatoryUnitOptions.find((el) => el.DicName === val);
      let obj = item.ApprovalUserRoleList.find((el) => el.DicName === val);
      this.$set(item, "ApprovalUserRoleKey", obj?.DicValue || "");

      this.ApprovalUserRoleDisabledList = this.SignatoryUnitList.map((el) => {
        return el.ApprovalUserRoleKey;
      });
      console.log(this.ApprovalUserRoleDisabledList, "222222222");
    },
    //审批单位或部门改变
    changeSignatoryUnit(val, item) {
      this.$set(item, "ApprovalUserRoleList", []);
      this.$set(item, "ApprovalUserRole", "");
      this.$set(item, "ApprovalUserRoleKey", "");
      let obj = this.SignatoryUnitOptions.find((el) => el.DicName === val);
      console.log(obj, "obj");
      this.$set(item, "ApprovalUserRoleList", obj?.DictionaryDetailsList || []);
      this.$set(item, "ApprovalOrganKey", obj?.DicKey || "");
      this.SignatoryUniDisabledList = this.SignatoryUnitList.map((el) => {
        return el.ApprovalOrganKey;
      });
      console.log(this.SignatoryUniDisabledList, "11111");

      // if (!obj) {
      //     let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
      //     if (selection.SignatoryUnitOptions) {
      //         selection.SignatoryUnitOptions.push({
      //             DicName: val,
      //             DicValue: val
      //         })
      //     } else {
      //         selection.SignatoryUnitOptions = [{
      //             DicName: val,
      //             DicValue: val
      //         }]
      //     }
      //     this.SignatoryUnitOptions.unshift({
      //         DicName: val,
      //         DicValue: val
      //     })
      //     window.sessionStorage.setItem('selection', JSON.stringify(selection))
      // }
    },
    //字典 专业
    getApprovalMajorList() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "RG_ApprovalMajor",
        })
        .then((res) => {
          if (res.Success) {
            this.ApprovalMajorList = res.Data.DictionaryDetailsList || [];
          }
        });
    },
    //图审字典 //部门 角色
    async getSignatoryUnitOptions() {
      // if (this.addAuditType == 1) {
      //     Keyword = 'RG_ExternalOrganization_CL'
      // } else if (this.addAuditType == 2) {
      //     Keyword = 'RG_ExternalOrganization_TS'
      // }
      await this.$http
        .post(
          "/Base_ProjectManage/Project_DictionaryMain/GetTreeDataListByDicKey?DicKey=RG_ExternalOrganization",
          {}
        )
        .then((res) => {
          if (res.Success) {
            this.SignatoryUnitOptions = res.Data || [];
            // let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
            // if (selection.SignatoryUnitOptions) {
            //     this.SignatoryUnitOptions = [...selection.SignatoryUnitOptions, ...this.SignatoryUnitOptions]
            // }
          }
        });
    },
    // 图审列表
    async getDataListByMoreCondition(ApprovalType) {
      if (ApprovalType == 1) {
        this.loadingLeft = true;
      } else {
        this.loadingRight = true;
      }
      await this.$http
        .post(
          "/ReviewGO/Review_ApprovalInfo/GetDataListByMoreConditionForUnitLatestRecord",
          {
            SortField: "CreateTime",
            SortType: "desc",
            Search: [
              {
                Condition: "TaskId",
                Keyword: this.TaskId,
              },
              {
                Condition: "ApprovalType",
                Keyword: ApprovalType,
              },
              {
                Condition: "CreatorId",
                Keyword: this.RGApprovalUnitUserInfo.Id,
              },
            ],
          }
        )
        .then((res) => {
          if (ApprovalType == 1) {
            this.loadingLeft = false;
          } else {
            this.loadingRight = false;
          }
          if (res.Success) {
            if (ApprovalType == 1) {
              this.auditMaterialsInfoList = res.Data || [];
            } else if (ApprovalType == 2) {
              this.auditImgInfoList = res.Data || [];
            }
          }
        });
    },
    padding1(num, length) {
      for (var len = (num + "").length; len < length; len = num.length) {
        num = "0" + num;
      }
      return num;
    },
    changeApprovalName() {
      this.addAuditFlagForm.ApprovalCode = this.addAuditFlagForm.ApprovalName;
    },
    SendNewApprovalMsg(Id) {
      this.$http
        .post("/ReviewGO/Review_ApprovalMsg/SendNewApprovalMsg?approvalId=" + Id, {})
        .then(res => {
          if (!res.Success) {
            if (this.isPCH5Text === "PC") {
              this.$message.error("提审消息发送失败");
            } else {
              this.$phoneMessage({
                message: "提审消息发送失败",
              });
            }
          }
        })
    },
    AuditOpen(item, num) {
      if (num === 3) {
        this.qrcodeVisible = true;
        this.qrcode = window.location.origin + "/RG_UserLogin";
        return;
      }
      this.$confirm("确认提审吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        customClass: this.isPCH5Text === "PC" ? "" : "h5-confirm-class",
      }).then(() => {
        this.$http
          .post("/ReviewGO/Review_ApprovalInfo/UpdateDataByCondition", {
            Conditions: [
              {
                Condition: "Status",
                Keyword: "1",
              },
            ],
            Ids: [item.Id],
          })
          .then((res) => {
            if (res.Success) {
              if (this.isPCH5Text === "PC") {
                this.$message.success("提审成功");
              } else {
                this.$phoneMessage({
                  message: "提审成功",
                });
              }
              this.SendNewApprovalMsg(item.Id)
              this.qrcodeVisible = true;
              this.qrcode = window.location.origin + "/RG_UserLogin";
              if (num == 1) {
                this.getDataListByMoreCondition(1);
              } else if (num == 2) {
                this.getDataListByMoreCondition(2);
              }
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    AuditDelete(item, num) {
      this.$confirm("此操作将删除该信息,确认删除吗", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        customClass: this.isPCH5Text === "PC" ? "" : "h5-confirm-class",
      }).then(() => {
        this.$http
          .post("/ReviewGO/Review_ApprovalInfo/DeleteData", [item.Id])
          .then((res) => {
            if (res.Success) {
              if (this.isPCH5Text === "PC") {
                this.$message.success("删除成功");
              } else {
                this.$phoneMessage({
                  message: "删除成功",
                });
              }
              if (num == 1) {
                this.getDataListByMoreCondition(1);
              } else if (num == 2) {
                this.getDataListByMoreCondition(2);
              }
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    //提审后查看/编辑详情
    editAuditOpen(item, num) {
      if (item.Status === 0) {
        this.addAuditOpen(num, item);
        return;
      }
      console.log(item, 1);
      this.addAuditType = num;

      this.activeNames = "";
      this.getApprovalOpinions(num);
      this.getAnnotationOpinions(num);
      this.ListAuditFlag = true;

      this.getListAuditData(item);
      // this.ViewAuditFlag = true
      // this.getViewAuditDataInfo(item)
    },
    //获取查看每个批次的详情
    getViewAuditDataInfo(item) {
      item.loading = true;
      let a = () => {
        // this.ViewAuditForm = {}
        this.$set(item.viewData, "ViewAuditForm", {});
        return this.$http
          .post("/ReviewGO/Review_ApprovalInfo/GetTheData", {
            id: item.Id,
          })
          .then((res) => {
            if (res.Success) {
              // this.ViewAuditForm = res.Data || {}
              this.$set(item.viewData, "ViewAuditForm", res.Data || {});
            } else {
              this.$message.error(res.Msg);
            }
          });
      };
      let b = () => {
        this.$set(item.viewData, "ViewUpFileData", []);
        return this.$http
          .post(
            "/ReviewGO/Review_ApprovalInfo_FAL/GetDataListByMoreCondition",
            {
              Search: [
                {
                  Condition: "ApprovalId",
                  Keyword: item.Id,
                },
              ],
            }
          )
          .then((res) => {
            if (res.Success) {
              if (res.Data && res.Data.length > 0) {
                res.Data.forEach((item) => {
                  item.name = item.FileName;
                  item.size = item.FileSize;
                  item.url = item.FileWebURL;
                  item.uid = item.Id;
                  item.status = "success";
                });
                // this.ViewUpFileData = res.Data
                this.$set(item.viewData, "ViewUpFileData", res.Data || []);
              } else {
                this.$set(item.viewData, "ViewUpFileData", []);
              }
            } else {
              this.$message.error(res.Msg);
            }
          });
      };
      let c = () => {
        // this.ViewSignatoryUnitOptions = []
        this.$set(item.viewData, "ViewSignatoryUnitOptions", []);
        return this.$http
          .post(
            "/ReviewGO/Review_ApprovalSignInfo/GetDataListByMoreCondition",
            {
              Search: [
                {
                  Condition: "ApprovalId",
                  Keyword: item.Id,
                },
              ],
            }
          )
          .then(async (res) => {
            if (res.Success) {
              // this.ViewSignatoryUnitOptions = res.Data || []
              this.$set(
                item.viewData,
                "ViewSignatoryUnitOptions",
                res.Data || []
              );

              for (const val of item.viewData.ViewSignatoryUnitOptions) {
                this.$set(val, "AuditUpFileData", []);
                await this.getitemUpList(val);
              }
            } else {
              this.$message.error(res.Msg);
            }
          });
      };
      Promise.all([a(), b(), c()])
        .then(() => {
          console.log(item, "item");
          item.loading = false;
        })
        .catch(() => {
          item.loading = false;
        });
    },
    async getitemUpList(item) {
      await this.$http
        .post(
          "/ReviewGO/Review_ApprovalSignInfo_FAL/GetDataListBySingleCondition",
          {
            Search: {
              Condition: "ApprovalSignId",
              Keyword: item.Id,
            },
          }
        )
        .then((res) => {
          if (res.Success) {
            res.Data.forEach((val) => {
              val.name = val.FileName;
              val.size = val.FileSize;
              val.url = val.FileWebURL;
              val.uid = val.Id;
              val.status = "success";
            });
            this.$set(item, "AuditUpFileData", res.Data);
          } else {
            this.$phoneMessage({
              message: "附件列表获取失败",
            });
          }
        });
    },
    ViewAuditFlagClose() {
      this.ViewAuditFlag = false;
    },
    downloadFile(item) {
      window.open(item.FileWebURL);
    },
    getSystemInfo() {
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        return "微信";
      } else {
        // 判断h5还是pc true就是h5
        let client =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        if (client) {
          return "H5";
        } else {
          return "PC";
        }
      }
    },
  },
  mounted() {
    // 添加 popstate 事件监听
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', this.onPopState);

  },
  async created() {
    this.isPCH5Text = this.getSystemInfo();
    window.addEventListener("resize", () => {
      this.isPCH5Text = this.getSystemInfo();
    });
    this.TaskId = window.localStorage.getItem("TaskId") || "";
    this.ApprovalUnit =
      JSON.parse(window.localStorage.getItem("ApprovalUnit")) || {};

    this.RGApprovalUnitUserInfo =
      JSON.parse(window.localStorage.getItem("RGApprovalUnitUserInfo")) || {};
    console.log(this.RGApprovalUnitUserInfo, "RGApprovalUnitUserInfo");
    console.log(this.ApprovalUnit, "ApprovalUnit");
    this.getTaskData();
    this.loadingLeft = true;
    this.loadingRight = true;
    this.getSignatoryUnitOptions();
    this.getDataListByMoreCondition(1);
    this.getDataListByMoreCondition(2);
    this.getApprovalMajorList();
    this.getSendMsg()
  },
  beforeDestroy() {
    // 在组件销毁前，移除 popstate 事件监听
    window.removeEventListener('popstate', this.onPopState)
    window.removeEventListener('resize', () => { })
  },
  computed: {
    ApprovalUserRoleDisabled() {
      return (item, val) => {
        if (this.ApprovalUserRoleDisabledList.indexOf(val.DicValue) === -1) {
          return false;
        } else {
          return true;
        }
      };
    },
    SignatoryUnitOptionsDisabled() {
      return (item, val) => {
        if (this.SignatoryUniDisabledList.indexOf(val.DicKey) === -1) {
          return false;
        } else {
          if (val.DictionaryDetailsList.length > 0) {
            return false;
          } else {
            return true;
          }
        }
      };
    },
    SignatoryUnitOptionsLength() {
      if (this.SignatoryUnitList.length === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
};
</script>
<style lang='scss' scoped>
.Shop_SubmitApprovalList {
  width: 100vw;

  min-width: 300px;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;

}

.div {
  margin: 0;
  line-height: 80px;
  font-weight: bold;
  font-size: 18px;
}

::v-deep .deepCard {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

::v-deep .Tasktitle {
  .el-descriptions-item__label {
    width: 200px;
    text-align: right;
    color: #000;
    font-size: 16px !important;
    font-weight: bold;
  }
}

::v-deep .TasktitleH5 {
  margin-top: 10px;

  .el-descriptions-item__label {
    width: 150px;
  }
}

::v-deep .el-form-item__label {
  font-size: 18px;
}

::v-deep .addAudit {
  margin-top: 5vh !important;
}

.ellipsis {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 2; //内容限制的行数
}

.auditMaterialsInfoList:hover {
  cursor: pointer;
  background: #f3eaea;
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}

.viewCard {
  height: 60vh;
}

::v-deep .ViewAudit {
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0px 20px;
  }
}

::v-deep .viewList {
  .el-descriptions-item__label {
    width: 40%;
    text-align: right;
    font-size: 16px !important;
    font-weight: bold !important;
  }
}

::v-deep .ListActive {
  .el-collapse-item__content {
    padding-bottom: 0;
  }
}

.Shop_SubmitApprovalListH5 {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  background: #eccece;
  position: relative;
}

::v-deep .el-tabs__content {
  height: calc(100vh - 60px);
  overflow-y: auto;

  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
}

::v-deep .H5dialog {
  margin: 0 !important;
  height: 100vh;
  overflow: hidden;

  .el-dialog__body {
    padding: 0px 20px;
  }

  .ViewAuditForm {
    .el-form-item__label {
      font-size: 14px;
      font-weight: bold;
      padding: 0;
    }

    .el-form-item {
      margin-bottom: 8px;
    }
  }

  .viewList {
    .el-descriptions-item__label {
      width: auto;
      text-align: left;
      font-size: 12px !important;
      font-weight: bold !important;
    }
  }
}

::v-deep .el-badge__content {
  right: 20px;
  top: 10px;
}
</style>